<template>
    <panel></panel>
</template>

<script>
import Panel from '../layout/Panel.vue';

export default {
  name: "Home",
  components: {
    Panel    
  },
  mounted() {
    location.assign('https://cams.clubcamgirl.com')
  }
};
</script>